import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";
function Crampcure() {
  const navigate = useNavigate();
  return (
    <div className="App" id="Environment">
      <Nav />
      {/* <div className="mx-auto p-5 space-x-10 mb-10 justify-items-center  text-center ">
        <button className="hover:bg-orange-500 border border-cyan-600 px-4 py-2 text-cyan-600 hover:border-orange-500 hover:text-white rounded-full ">
          Environment
        </button>
        <button className="hover:bg-orange-500 border border-cyan-600 px-4 py-2 text-cyan-600 hover:border-orange-500 hover:text-white rounded-full ">
          Disinfect
        </button>
        <button className="hover:bg-orange-500 border border-cyan-600 px-4 py-2 text-cyan-600 hover:border-orange-500 hover:text-white rounded-full ">
          Health
        </button>
      </div> */}
      {/* <section className=" body-font xl:px-10">
        <div className="container px-5 py-2 mx-auto flex flex-wrap ">
          <div className="flex flex-wrap md:-m-2 -m-1">
       
            <div className="flex flex-wrap md:w-1/2 rounded-2xl shadow-lg ">
              <div className="md:p-2 p-1 w-full ">
                <div className="bg-white  p-10 ">
                  <img className="mx-auto " src="images/Dr_Grow_Alkasense (1).png" />
                </div>
              </div>
           
            </div>
            <div className="lg:flex-grow  md:w-1/2  flex flex-col md:items-start md:text-left mb-16 md:mb-0 rounded-2xl shadow-lg  ">
              <div className="md:p-2 p-1 w-full">
                <div className="bg-white p-10 ">
                  <h1 className="text-3xl xl:text-4xl font-bold ">
                  Alkasense
                  </h1>
                  <h4 className="text-xl  pb-3 py-2  font-semibold py-4">
                  Sodium Bicarbonate for Aquaculture Use
                   
                  </h4>
                  <div className="  pb-2">
                    <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    pH Control
                    </span>
                    <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Alkalinity 
                    </span>
                    <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Water Quality
                    </span>
                  </div>
                  <h2 className="text-2xl pt-3 font-bold  ">
                  Contains
                  </h2>
                  <p className="pb-3">Sodium Bicarbonate - 99%</p>
                  <h2 className="text-2xl  font-bold  ">Benefits</h2>
                  <p className="">
                  <ol>
  <li><span className="text-3xl">&#8226; &nbsp;</span>Increases the alkalinity of the pond water.</li>
  <li><span className="text-3xl">&#8226; &nbsp;</span>Maintains the pH levels.</li>
  <li><span className="text-3xl">&#8226; &nbsp;</span>Contributes to the shrimp’s health and stress reduction. </li>
  <li className=""><span className="text-3xl">&#8226; &nbsp;</span>Removes bad odour from ponds.
  </li>
  
</ol>
 </p>
                  <h2 className="text-2xl  font-bold  mt-5" >Dosage</h2>
                  <p className="">
                  7-8 kg/acre or As advised by an aquaculture technician.
                  </p>
                

                  <h2 className="text-2xl pt-3 font-bold  mt-5">
                  Application Method
                  </h2>
                  <p className="pb-3">Apply directly or mix with dry sand and broadcast throught the pond.</p>
  </div>
              </div>
            </div>

          </div>
        </div>
      </section> */}
      <section className=" body-font xl:px-6">
        <div className="container px-5 xl:px-10 py-2 mx-auto flex ">
          <div className="flex w-full md:-m-2 -m-1">
            <div className="flex-1 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg flex items-center justify-center h-full">
                <img
                  className="mx-auto max-h-full"
                  src="images/Dr_Grow_Alkasense (1).png"
                  alt="Alkasense"
                />
              </div>
            </div>

            <div className="flex-1 md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg p-5 h-full">
                <h1 className="text-3xl xl:text-4xl font-bold  py-5">
                  Alkasense
                </h1>
                <h4 className="text-xl pb-3  font-semibold">
                  Sodium Bicarbonate for Aquaculture Use
                </h4>
                <div className="pb-5">
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    pH Control
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Alkalinity
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Water Quality
                  </span>
                </div>

                <h2 className="text-2xl font-bold ">Contents</h2>
                <p className="pb-3">Sodium bicarbonate - 99%.</p>

                <h2 className="text-2xl font-bold  mt-4">Benefits</h2>
                <ul>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Increases
                    the alkalinity of the pond water.
                  </li>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Maintains
                    the pH level.{" "}
                  </li>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Contributes
                    to the shrimp’s health and stress reduction.
                  </li>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Removes bad
                    odour from ponds.
                  </li>
                </ul>

                <h2 className="text-2xl font-bold  mt-5">Dosage</h2>
                <p>7-8 kg/acre or As advised by an aquaculture expert.</p>
                <h2 className="text-2xl pt-3 font-bold  mt-5">
                  Application Method
                </h2>
                <p className="pb-3">
                  Apply directly or mix with dry sand and broadcast throughout
                  the pond.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="">
        <h2 className="text-4xl font-bold ml-2 sm:ml-10 px-5 pt-10 2xl:px-44">
          Other Products{" "}
        </h2>
      </div>
      <div className="flex flex-wrap xl:px-10 2xl:px-40">
        <div className="md:flex sm:w-1/2 lg:w-1/4  xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg cursor-pointer"
            onClick={() => navigate("/teaseedpowder")}
          >
            <img
              className="mx-auto"
              src="images/Dr_Grow_Tea_Seed_Powder (1).png"
            />
            <h3 className="text-xl font-bold  text-center">Tea seed Powder</h3>
            <div className="px-6 lg:px-3 pt-4 pb-2">
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Wild Fishes
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Crabs
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Snails
              </span>
            </div>
          </div>
        </div>
        <div className="md:flex sm:w-1/2  lg:w-1/4 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg cursor-pointer"
            onClick={() => navigate("/aquapromsl")}
          >
            <img className="mx-auto" src="images/Dr_Grow_Aqua_Pro-MSL.png" />
            <h3 className="text-xl font-bold  text-center">Aqua Pro-MSL</h3>
            <div className="px-6 lg:px-3 pt-4 pb-2 ">
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Green Colonies
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Toxic Gases
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                All-Weather
              </span>
            </div>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/gutbot")}
          >
            <img
              className="mx-auto"
              src="images/Dr_Grow_Gut_Bot 1.png"
              alt=""
            />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">Gut Bot</span>
              </h3>

              {/* <span
                onClick={() => navigate("/gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Gut health
              </span>
              <span
                onClick={() => navigate("/gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Digestion
              </span>
              <span
                onClick={() => navigate("/gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Immunity
              </span> */}
            </div>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/ecobot")}
          >
            <img
              className="mx-auto"
              src="images/Dr_Grow_Eco_Bot 1.png"
              alt=""
            />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">ECO BOT</span>
              </h3>

              <span
                onClick={() => navigate("/ecobot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Vibrio
              </span>
              <span
                onClick={() => navigate("/ecobot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Organic Loads
              </span>
              <span
                onClick={() => navigate("/ecobot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Gases
              </span>
            </div>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/zeoforce")}
          >
            <img className="mx-auto" src="images/product_zeoforce.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">Zeo-Force</span>
              </h3>

              <span
                onClick={() => navigate("/zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Improves Do
              </span>
              <span
                onClick={() => navigate("/zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Removal
              </span>
              <span
                onClick={() => navigate("/zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Ideal Water Quality
              </span>
            </div>
          </div>
        </div>
        {/* <div className="md:flex sm:w-1/2 lg:w-1/4  p-5">
          <div className="bg-white p-10 rounded-2xl shadow-lg">
            <img className="mx-auto" src="images/Vibrio.png" />
            <h3 className="text-xl font-bold  text-center">
              Vibrio Terminator
            </h3>
            <div className="px-6 lg:px-3 pt-4 pb-2">
              <span className="inline-block  bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Vibrio
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                HarmfulPathogens
              </span>
            </div>
          </div>
        </div>
        <div className="md:flex sm:w-1/2 lg:w-1/4 p-5">
          <div className="bg-white p-10 rounded-2xl shadow-lg">
            <img className="mx-auto" src="images/Vibrio.png" />
            <h3 className="text-xl font-bold  text-center">
              Vibrio Terminator
            </h3>
            <div className="px-6 lg:px-3 pt-4 pb-2">
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Vibrio
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Ammonia
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Nitrate
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Crampcure;
