import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Navbar } from "react-bootstrap";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Crampcure from "./Crampcure";
// import PondBp from "./PondBP";
function Alkasense() {
  const navigate = useNavigate();

  return (
    <div className="App" id="Environment">
      <Nav />
      <section className="text-black body-font xl:px-6">
        <div className="container px-5 xl:px-10 py-2 mx-auto flex flex-wrap">
          <div className="flex flex-wrap md:-m-2 -m-1">
            <div className="md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg flex items-center justify-center h-full">
                <img
                  className="mx-auto px-10 max-h-full"
                  src="images/product_ecobot.png"
                  alt="Eco-Bot"
                />
              </div>
            </div>

            <div className="lg:flex-grow md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg p-5 h-full">
                <h1 className="text-3xl xl:text-4xl font-bold  py-5">
                  ECO BOT
                </h1>
                <h4 className="text-xl pb-3  font-semibold">
                  Water & Soil Probiotics
                </h4>
                {/* <div className="pb-5">
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Vibrio
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Organic Loads
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Toxic Gases
                  </span>
                </div> */}

                <h2 className="text-2xl font-bold  mb-2">Benefits</h2>
                <ul className="list-square pl-5">
                  <li>
                    Inhibits the growth of harmful microorganisms like Vibrio
                    through competitive exclusion.
                  </li>
                  <li>Improves FCR and weight gain.</li>
                  <li>
                    Promotes shrimp health through beneficial plankton
                    enhancement.
                  </li>
                  <li>
                    Reduces toxic gases like ammonia and H<sub>2</sub>S.
                  </li>
                  <li>
                    Efficient results in broader range of salinity and pH etc.
                  </li>
                </ul>

                <h2 className="text-2xl font-bold  mb-2 mt-5">
                  Dosage and method of application
                </h2>
                <p>
                  <strong>For shrimp:</strong> 400 g/acre at 1 m water depth.
                </p>
                <p className="pt-2">
                  <strong>For fish:</strong> 100 g/acre at 1 m water depth.
                </p>
                <p className="pt-2">
                  <strong>Feed application:</strong> 5–10 g/kg of feed.
                </p>
                <p className="pt-2 font-bold">
                  (or as advised by an Aqua Consultant)
                </p>
                <h2 className="text-2xl font-bold  mt-4">Composition</h2>
                <p className="py-3 pr-10">
                  Consortium of beneficial microorganisms including{" "}
                  <strong>
                    Bacillus subtilis, Bacillus coagulans, Bacillus
                    licheniformis, Bacillus polymyxa, Bacillus megaterium,
                    Bacillus amyloliquefaciens,
                  </strong>
                  with a concentration of 5 billion CFU/g
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className=" ">
        <h2 className="text-4xl font-bold ml-2 sm:ml-10 px-5 pt-5 2xl:px-44">
          {" "}
          Other Products
        </h2>
      </div>
      <div className="flex flex-wrap xl:ml-10 2xl:px-40">
        <div className="md:flex sm:w-1/2 lg:w-1/4 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg cursor-pointer"
            onClick={() => navigate("/teaseedpowder")}
          >
            <img
              className="mx-auto "
              src="images/product_tea_seed_powder2.png"
            />
            <h3 className="text-xl font-bold  text-center">Tea Seed Powder</h3>
            {/* <div className="px-6 lg:px-3 pt-4 ">
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Wild Fishes
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Crabs
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Snails
              </span>
            </div> */}
          </div>
        </div>
        {/* <div className="md:flex sm:w-1/2 lg:w-1/4 xl:w-1/3 p-5">
          <div className="bg-white p-10 rounded-2xl shadow-lg cursor-pointer" onClick={() => navigate("/alkasense")}>
            <img onClick={() => navigate("/alkasense")}className="mx-auto " src="images/Dr_Grow_Alkasense (1).png" />
            <h3 className="text-xl font-bold  text-center">
              Alkasense
            </h3>
            <div className="px-6 lg:px-3 pt-4 pb-2">
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
               pH Control
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Alkalinity
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
               Water Quality
              </span>
             
            </div>
          </div>
        </div> */}

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/gutbot")}
          >
            <img className="mx-auto" src="images/Dr_Grow_Gut_Bot.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">Gut Bot</span>
              </h3>

              {/* <span
                onClick={() => navigate("/gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Gut health
              </span>
              <span
                onClick={() => navigate("/gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Digestion
              </span>
              <span
                onClick={() => navigate("/gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Immunity
              </span> */}
            </div>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/zeoforce")}
          >
            <img className="mx-auto" src="images/product_zeoforce.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">Zeo-Force</span>
              </h3>

              {/* <span
                onClick={() => navigate("/zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Improves Do
              </span>
              <span
                onClick={() => navigate("/zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Removal
              </span>
              <span
                onClick={() => navigate("/zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Ideal Water Quality
              </span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Alkasense;
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<PondBp />);
