import React, { Component } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";

function Virkill() {
  const navigate = useNavigate();

  return (
    <div className="App" id="Environment">
      <Nav />
      <section className="text-black body-font xl:px-6">
        <div className="container px-5 xl:px-10 py-2 mx-auto flex flex-wrap">
          <div className="flex flex-wrap md:-m-2 -m-1">
            <div className="md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg flex items-center justify-center h-full">
                <img
                  className="mx-auto px-10 max-h-full"
                  src="images/product_virkill.png"
                  alt="virkill"
                />
              </div>
            </div>

            <div className="lg:flex-grow md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg p-5 h-full">
                <h1 className="text-3xl xl:text-4xl font-bold  py-5">
                  VIRKILL
                </h1>
                <h4 className="text-xl pb-3  font-semibold">
                  Supreme Viral Protector
                </h4>

                <h2 className="text-2xl font-bold  mb-2">Benefits</h2>
                <ul className="list-square pl-5">
                  <li>
                    Attacks Free Virions Effectively and Reduces Viral Load.
                  </li>
                  <li>
                    Enhances Feed Intake, Nutrient Absorption, and Improves FCR.
                  </li>
                  <li>Boosts Immunity for Stronger Viral Resistance.</li>
                  <li>
                    Provides a Protective Shield and Delays Viral Infections.
                  </li>
                </ul>

                <h2 className="text-2xl font-bold  mb-2 mt-5">
                  Dosage and method of application
                </h2>
                <p>
                  Mix 25-30 ml/kg of feed with a suitable amount of water and
                  spray evenly onto the feed. Shade-dry the feed for 15 to 20
                  minutes before applying it in the pond.
                </p>
                <p className="before:content-['\2022'] before:mr-2">
                  For Better Infection Control: Use VIR KILL™ + RemoVIR™.
                </p>
                <p className="before:content-['\2022'] before:mr-2">
                  For Faster Growth: Use VIR KILL™ + GroMAXX™.
                </p>
                <p className="pt-2">
                  Note: Begin use on the second day after stocking. <br />
                  <em>(or as advised by an Aqua Consultant)</em>
                </p>

                <h2 className="text-2xl font-bold  mt-4">Composition</h2>
                <p className="py-3 pr-10 before:content-['\2022'] before:mr-2">
                  Virus deactivating agents, binders, herbal extracts, enzymes,
                  and stabilizers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className=" ">
        <h2 className="text-4xl font-bold ml-2 sm:ml-10 px-5 pt-5 2xl:px-44">
          {" "}
          Other Products
        </h2>
      </div>
      <div className="flex flex-wrap xl:ml-10 2xl:px-40">
        <div className="md:flex sm:w-1/2 lg:w-1/4 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg cursor-pointer"
            onClick={() => navigate("/gromaxx")}
          >
            <img className="mx-auto " src="images/product_gromaxx.png" />
            <h3 className="text-xl font-bold  text-center">GroMAXX</h3>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/removir")}
          >
            <img className="mx-auto" src="images/product_removir.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">RemoVIR</span>
              </h3>
            </div>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/doturbo")}
          >
            <img className="mx-auto" src="images/product_doturbo.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">D O Turbo</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Virkill;
