import React, { Component } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";

function DoTurbo() {
  const navigate = useNavigate();

  return (
    <div className="App" id="Environment">
      <Nav />
      <section className="text-black body-font xl:px-6">
        <div className="container px-5 xl:px-10 py-2 mx-auto flex flex-wrap">
          <div className="flex flex-wrap md:-m-2 -m-1">
            <div className="md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg flex items-center justify-center h-full">
                <img
                  className="mx-auto px-10 max-h-full"
                  src="images/product_doturbo.png"
                  alt="D O Turbo"
                />
              </div>
            </div>

            <div className="lg:flex-grow md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg p-5 h-full">
                <h1 className="text-3xl xl:text-4xl font-bold  py-5">
                  D O Turbo
                </h1>
                <h4 className="text-xl pb-3  font-semibold">
                  Ultrafast D O Activation
                </h4>

                <h2 className="text-2xl font-bold  mb-2">Benefits</h2>
                <ul className="list-square pl-5">
                  <li>
                    Enhances dissolved oxygen through sustained release at pond
                    bottom.
                  </li>
                  <li>Instantly relieves stress in shrimp and fish.</li>
                  <li>
                    Lowers mortality rates and reduces the risk of disease
                    outbreaks.
                  </li>
                  <li>Leads to a healthier and more stable pond ecosystem.</li>
                  <li>
                    Supports oxidation of the pond bottom to improve overall
                    water quality.
                  </li>
                </ul>

                <h2 className="text-2xl font-bold  mb-2 mt-5">
                  Dosage and method of application
                </h2>
                <p className="before:content-['\2022'] before:mr-2">
                  1 kg/ha in one meter water depth.
                </p>
                <p className="pt-2 before:content-['\2022'] before:mr-2">
                  Directly broadcast evenly throughout the pond. <br />{" "}
                  <em>(or as advised by an Aqua Consultant)</em>
                </p>

                <h2 className="text-2xl font-bold  mt-4">Composition</h2>
                <p className="py-3 pr-10 before:content-['\2022'] before:mr-2">
                  Sodium percarbonate (12%), Adsorbent (17%),
                  Tetraacetylethylenediamine (TAED) (3%), and deodorizers in a
                  suitable concentration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className=" ">
        <h2 className="text-4xl font-bold ml-2 sm:ml-10 px-5 pt-5 2xl:px-44">
          {" "}
          Other Products
        </h2>
      </div>
      <div className="flex flex-wrap xl:ml-10 2xl:px-40">
        <div className="md:flex sm:w-1/2 lg:w-1/4 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg cursor-pointer"
            onClick={() => navigate("/teaseedpowder")}
          >
            <img
              className="mx-auto "
              src="images/product_tea_seed_powder2.png"
            />
            <h3 className="text-xl font-bold  text-center">Tea Seed Powder</h3>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/gutbot")}
          >
            <img className="mx-auto" src="images/Dr_Grow_Gut_Bot.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">Gut Bot</span>
              </h3>
            </div>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/zeoforce")}
          >
            <img className="mx-auto" src="images/product_zeoforce.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">Zeo-Force</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoTurbo;
