import React from "react";
import ReactDOM from "react-dom/client";
import { useNavigate } from "react-router-dom";
// import PondBp from "./PondBP";

export default function Product() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row px:10 xl:px-4 2xl:px-10">
      <div className="grid gap-1 md:gap-6 md:grid-cols-3 grid-cols-2 items-center xl:px-10 2xl:px-10">
        <div>
          <div
            className="bg-white p-5 md:p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("minergymax")}
          >
            <img
              className="mx-auto"
              src="images/product_minergy_max.png"
              alt=""
            />

            <div>
              <h3 className="text-base md:text-xl font-bold text-center mb-2">
                <span className="lust ">Minergy Max</span>
              </h3>

              {/* <span
                onClick={() => navigate("zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Improves DO
              </span>
              <span
                onClick={() => navigate("zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Removal
              </span>
              <span
                onClick={() => navigate("zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Ideal Water Quality
              </span> */}
            </div>
          </div>
        </div>
        <div>
          <div
            className="bg-white p-5 md:p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("ps")}
          >
            <img className="mx-auto" src="images/product_ps_no1.png" alt="" />

            <div>
              <h3 className="text-base md:text-xl font-bold text-center mb-2">
                <span className="lust ">PS No.1</span>
              </h3>

              {/* <span
                onClick={() => navigate("Aquapromsl")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Green Colonies
              </span>
              <span
                onClick={() => navigate("Aquapromsl")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Gases
              </span>
              <span
                onClick={() => navigate("Aquapromsl")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                All-Weather
              </span> */}
            </div>
          </div>
        </div>
        <div>
          <div
            className="bg-white p-5 md:p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("ecobot")}
          >
            <img className="mx-auto" src="images/product_ecobot.png" alt="" />

            <div>
              <h3 className="text-base md:text-xl font-bold text-center mb-2">
                <span className="lust">ECO BOT</span>
              </h3>

              {/* <span
                onClick={() => navigate("ecobot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Vibrio
              </span>
              <span
                onClick={() => navigate("ecobot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Organic Loads
              </span>
              <span
                onClick={() => navigate("ecobot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Gases
              </span> */}
            </div>
          </div>
        </div>

        <div>
          <div
            className="bg-white p-5 md:p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("doturbo")}
          >
            <img className="mx-auto" src="images/product_doturbo.png" alt="" />
            <div>
              <h3 className="text-base md:text-xl font-bold  text-center mb-2">
                <span className="lust font-bold">D O Turbo</span>
              </h3>
            </div>
          </div>
        </div>

        <div>
          <div
            className="bg-white p-5 md:p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("gromaxx")}
          >
            <img className="mx-auto" src="images/product_gromaxx.png" alt="" />
            <div>
              <h3 className="text-base md:text-xl font-bold  text-center mb-2">
                <span className="lust font-bold">GroMAXX</span>
              </h3>
            </div>
          </div>
        </div>

        <div>
          <div
            className="bg-white p-5 md:p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("virkill")}
          >
            <img className="mx-auto" src="images/product_virkill.png" alt="" />
            <div>
              <h3 className="text-base md:text-xl font-bold  text-center mb-2">
                <span className="lust font-bold">VIRKILL</span>
              </h3>
            </div>
          </div>
        </div>

        <div>
          <div
            className="bg-white p-5 md:p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("removir")}
          >
            <img className="mx-auto" src="images/product_removir.png" alt="" />
            <div>
              <h3 className="text-base md:text-xl font-bold  text-center mb-2">
                <span className="lust font-bold">RemoVIR</span>
              </h3>
            </div>
          </div>
        </div>

        <div>
          <div
            className="bg-white p-5 md:p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("teaseedpowder")}
          >
            <img
              className="mx-auto"
              src="images/product_tea_seed_powder2.png"
              alt=""
            />
            <div>
              {/* <button> */}
              <h3 className="text-base md:text-xl font-bold  text-center mb-2">
                <span className="lust font-bold">Tea Seed Powder</span>
              </h3>

              {/* <a className="text-white">
                <span
                  onClick={() => navigate("teaseedpowder")}
                  className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
                >
                  Wild Fishes
                </span>
              </a>
              <span
                onClick={() => navigate("teaseedpowder")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Crabs
              </span>
              <span
                onClick={() => navigate("teaseedpowder")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2 "
              >
                Snails
              </span> */}
              {/* </button> */}
            </div>
          </div>
        </div>

        {/* <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("Alkasense")}
          >
            <img
           
              className="mx-auto"
              src="images/Dr_Grow_Alkasense (1).png"
              alt=""
            />
            <div>
            
              <div>
                <h3 className="text-base md:text-xl font-bold  text-center mb-2 ">
                  <span className="lust ">Alkasense</span>
                </h3>
              </div>

              <div>
                <span
                  onClick={() => navigate("Alkasenseos")}
                  className="inline-block  bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
                >
                  pH Control
                </span>
                <span
                  onClick={() => navigate("Alkasense")}
                  className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
                >
                  Alkalinity
                </span>
                <span
                  onClick={() => navigate("Alkasense")}
                  className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
                >
                  Water Quality
                </span>

              </div>
            
            </div>
          </div>
        </div> */}

        <div>
          <div
            className="bg-white p-5 md:p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("zeoforce")}
          >
            <img className="mx-auto" src="images/product_zeoforce.png" alt="" />

            <div>
              <h3 className="text-base md:text-xl font-bold text-center mb-2">
                <span className="lust ">Zeo-Force</span>
              </h3>

              {/* <span
                onClick={() => navigate("zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Improves DO
              </span>
              <span
                onClick={() => navigate("zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Removal
              </span>
              <span
                onClick={() => navigate("zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Ideal Water Quality
              </span> */}
            </div>
          </div>
        </div>

        <div>
          <div
            className="bg-white p-5 md:p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("gutbot")}
          >
            <img className="mx-auto" src="images/product_gutbot.png" alt="" />

            <div>
              <h3 className="text-base md:text-xl font-bold text-center mb-2">
                <span className="lust ">Gut Bot</span>
              </h3>

              {/* <span
                onClick={() => navigate("gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Gut health
              </span>
              <span
                onClick={() => navigate("gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
               Digestion
              </span>
              <span
                onClick={() => navigate("gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Immunity
              </span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
