import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Navbar } from "react-bootstrap";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Crampcure from "./Crampcure";
function PondBp() {
  const navigate = useNavigate();

  return (
    <div className="App" id="Environment">
      <Nav />
      {/* <div className="mx-auto p-5 space-x-10 mb-10 justify-items-center  text-center ">
        <button className="hover:bg-orange-500 border border-cyan-600 px-4 py-2 text-cyan-600 hover:border-orange-500 hover:text-white rounded-full ">
          Environment
        </button>
        <button className="hover:bg-orange-500 border border-cyan-600 px-4 py-2 text-cyan-600 hover:border-orange-500 hover:text-white rounded-full ">
          Disinfect
        </button>
        <button className="hover:bg-orange-500 border border-cyan-600 px-4 py-2 text-cyan-600 hover:border-orange-500 hover:text-white rounded-full ">
          Health
        </button>
      </div> */}
      {/* <button  className="text-gray-500 ml-3" onClick={history.goback()}>
         Home	&gt;
        </button> */}

      {/* 
      <section className=" body-font ">
        <div className="container px-5 py-2  mx-auto flex flex-wrap lg:px-20 ">
          <div className="flex flex-wrap md:-m-2 -m-1">
     
            <div className="flex flex-wrap md:w-1/2 rounded-2xl shadow-lg">
              <div className="md:p-10 p-1 w-full ">
                <div className="bg-white   ">
                  <img className="mx-auto" src="images/Dr_Grow_Tea_Seed_Powder (1).png" />
                </div>
              </div>
             
            </div>
           
            <div className="lg:flex-grow  md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 rounded-2xl shadow-lg ">
              <div className="md:p-10 p-1 w-full">
                <div className="bg-white   ">
                  <h1 className="text-3xl xl:text-4xl font-bold ">
                   Tea Seed Powder-25kg
                  </h1>
                  <h4 className="text-xl  pb-3 py-2  font-semibold py-4">
                  Enriched saponin to eliminate weed species
                   
                  </h4>
                  <div className="  pb-2">
                    <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Wild Fishes
                    </span>
                    <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Crabs 
                    </span>
                    <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Snails
                    </span>
                  </div>
                  <h2 className="text-2xl  font-bold  ">Benefits</h2>
                  <p className="">
                  <ol>
  <li><span className="text-3xl">&#8226; &nbsp;</span>Eliminates unwanted fishes, snails etc.</li>
  <li><span className="text-3xl">&#8226; &nbsp;</span>Helps a dry pond become more fertile.
  </li>
  
  <li className=""><span className="text-3xl">&#8226; &nbsp;</span>Enhances the growth of phytoplankton.</li>
  <li><span className="text-3xl">&#8226; &nbsp;</span>Stimulate shrimp moulting.</li>
  <li><span className="text-3xl">&#8226; &nbsp;</span>Acts as natural bacteriocides and fungicides.
  </li>
</ol>
 </p>
                  <h2 className="text-2xl  font-bold  mt-5" >Dosage
</h2>

<p className="pb-2 pt-2">
20 kg/acre at one ft pond water depth.So, in 5ft water depth, apply 100 kg/acre.
(or as advised by an Aquaculture Expert)
 </p>
 <p>Best results will be gained when applied at 8:00 am.
 </p>


  </div>
              </div>
            </div>
            </div>
          
        </div>
      </section> */}

      <section className=" body-font xl:px-6">
        <div className="container px-5 xl:px-10 py-2 mx-auto flex flex-wrap">
          <div className="flex flex-wrap md:-m-2 -m-1">
            <div className="md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg flex items-center justify-center h-full">
                <img
                  className="mx-auto max-h-full"
                  src="images/product_tea_seed_powder2.png"
                  alt="Teeseedpowder"
                />
              </div>
            </div>

            <div className="lg:flex-grow md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg p-5 h-full">
                <h1 className="text-3xl xl:text-4xl font-bold  py-5">
                  Tea Seed Powder
                </h1>
                <h4 className="text-xl pb-3  font-semibold">
                  Enriched saponin to eliminate weed species
                </h4>
                {/* <div className="pb-5">
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Wild Fishes
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Crabs
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Snails
                  </span>
                </div> */}

                <h2 className="text-2xl font-bold ">Contents</h2>
                <p className="pb-3">
                  Tea Seed Powder is a kind of residue extracted from camellia
                  seeds that contains 15%-18% tea saponin.
                </p>

                <h2 className="text-2xl font-bold  mt-4">Benefits</h2>
                <ul>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Eliminates
                    unwanted fishes, snails etc.
                  </li>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Helps a dry
                    pond become more fertile.
                  </li>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Enhances the
                    growth of phytoplankton.
                  </li>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Stimulate
                    shrimp moulting.
                  </li>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Acts as
                    natural bacteriocides and fungicides.
                  </li>
                </ul>

                <h2 className="text-2xl font-bold  mt-5">Dosage</h2>
                <p>
                  20 kg/acre at one ft pond water depth.So, in 5ft water depth,
                  apply 100 kg/acre. (or as advised by an Aquaculture Expert)
                </p>
                <p className="pt-2">
                  Best results will be gained when applied at 8:00 am.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className=" ">
        <h2 className="text-4xl sm:text-4xl font-bold ml-2 sm:ml-10 px-5 pt-5 2xl:px-44">
          {" "}
          Other Products
        </h2>
      </div>
      <div className="flex flex-wrap xl:ml-10 2xl:px-40">
        {/* <div className="md:flex sm:w-1/2 lg:w-1/4 xl:w-1/3 p-5">
          <div className="bg-white p-10 rounded-2xl shadow-lg cursor-pointer" onClick={() => navigate("/alkasense")}>
            <img onClick={() => navigate("/alkasense")}className="mx-auto" src="images/Dr_Grow_Alkasense (1).png" />
            <h3 className="text-xl font-bold  text-center">
              Alkasense
            </h3>
            <div className="px-6 lg:px-3 pt-4 pb-2">
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
               pH Control
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Alkalinity
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
               Water Quality
              </span>
             
            </div>
          </div>
        </div> */}

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/gutbot")}
          >
            <img
              className="mx-auto"
              src="images/Dr_Grow_Gut_Bot 1.png"
              alt=""
            />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">Gut Bot</span>
              </h3>

              {/* <span
                onClick={() => navigate("/gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Gut health
              </span>
              <span
                onClick={() => navigate("/gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Digestion
              </span>
              <span
                onClick={() => navigate("/gutbot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Immunity
              </span> */}
            </div>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/ecobot")}
          >
            <img className="mx-auto" src="images/product_ecobot.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">ECO BOT</span>
              </h3>

              {/* <span
                onClick={() => navigate("/ecobot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Vibrio
              </span>
              <span
                onClick={() => navigate("/ecobot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Organic Loads
              </span>
              <span
                onClick={() => navigate("/ecobot")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Gases
              </span> */}
            </div>
          </div>
        </div>
        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/zeoforce")}
          >
            <img className="mx-auto" src="images/product_zeoforce.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">Zeo-Force</span>
              </h3>

              {/* <span
                onClick={() => navigate("/zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Improves Do
              </span>
              <span
                onClick={() => navigate("/zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Removal
              </span>
              <span
                onClick={() => navigate("/zeoforce")}
                className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Ideal Water Quality
              </span> */}
            </div>
          </div>
        </div>

        {/* <div className="md:flex sm:w-1/2  lg:w-1/4 xl:w-1/3 p-5">
          <div className="bg-white p-10 rounded-2xl shadow-lg cursor-pointer" onClick={() => navigate("/pro")}>
            <img onClick={() => navigate("/pro")}className="mx-auto" src="images/cal&mag.png" />
            <h3 className="text-xl font-bold  text-center">
              Calcium And Magnesium Booster
            </h3>
            <div className="px-6 lg:px-3 pt-4 pb-2 ">
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Improper Moulting
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                PoorShell
              </span>
              <span className="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Mineral Imbalance
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default PondBp;
