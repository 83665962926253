import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom/client";
function CMB() {
  const navigate = useNavigate();
  return (
    <div className="App" id="Environment">
      <Nav />

      <section className=" body-font xl:px-6">
        <div className="container px-5 xl:px-10  py-2 mx-auto flex flex-wrap">
          <div className="flex flex-wrap md:-m-2 -m-1">
            <div className="md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg flex items-center justify-center h-full">
                <img
                  className="mx-auto max-h-full"
                  src="images/Dr_Grow_Aqua_Pro-MSL.png"
                  alt="Aqua Pro-MSL"
                />
              </div>
            </div>

            <div className="lg:flex-grow md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg p-5 h-full">
                <h1 className="text-3xl xl:text-4xl font-bold  py-5">
                  Aqua Pro-MSL
                </h1>
                <h4 className="text-xl pb-3  font-semibold">
                  Multistrain all-weather live probiotic formulation for
                  aquaculture use
                </h4>
                <h2 className="text-2xl font-bold ">Contents</h2>
                <p className="pb-3">
                  Contains unique combination of 14 bacteria including{" "}
                  <i>
                    Bacillus spp., Lactobacillus spp., Thiobacillus spp.,
                    Bifidobacterium spp., Nitrobacter sp., and Nitrosomonas sp.
                  </i>{" "}
                  with a total microbial population of 40 billion CFU/ml at the
                  time of packaging.
                </p>

                <h2 className="text-2xl font-bold  mt-4">Benefits</h2>
                <ul>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Enhances
                    beneficial microbes in the pond ecosystem through
                    competitive exclusion.
                  </li>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Controls
                    Vibrio and harmful bacteria in the pond.
                  </li>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Inhibits
                    toxic gases like ammonia and H<sub>2</sub>S.
                  </li>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Improves
                    pond carrying capacity.
                  </li>
                  <li>
                    <span className="text-3xl">&#8226; &nbsp;</span>Improves FCR
                    and marketable quality of shrimp.
                  </li>
                </ul>

                <h2 className="text-2xl font-bold  mt-5">Dosage</h2>
                <p>
                  Prior to stocking: 2 litre per hectare at 1m water depth.
                  <br />
                  <strong>During culture period:</strong> 1 litre per hectare at
                  1m water depth (or advised by an aquaculture expert).
                </p>

                <h2 className="text-2xl font-bold  mt-5">Application</h2>
                <p>
                  Mix 1 L of Aqua Pro - MSL with 1-2 kg of jaggery or molasses
                  in 20 L of water for a 1 Ha pond. Ferment the solution
                  overnight for 12 hours for the best results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className=" ">
        <h2 className="text-4xl font-bold ml-2 sm:ml-10 px-5 pt-5 2xl:px-44">
          {" "}
          Other Products
        </h2>
      </div>
      <div className="flex flex-wrap xl:ml-10 2xl:px-40 ">
        <div className="md:flex sm:w-1/2 lg:w-1/4 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg cursor-pointer"
            onClick={() => navigate("/teaseedpowder")}
          >
            <img
              className="mx-auto "
              src="images/product_tea_seed_powder2.png"
            />
            <h3 className="text-xl font-bold  text-center">Tea Seed Powder</h3>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/gutbot")}
          >
            <img
              className="mx-auto"
              src="images/Dr_Grow_Gut_Bot 1.png"
              alt=""
            />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">Gut Bot</span>
              </h3>
            </div>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/ecobot")}
          >
            <img className="mx-auto" src="images/product_ecobot.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">ECO BOT</span>
              </h3>
            </div>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/zeoforce")}
          >
            <img className="mx-auto" src="images/product_zeoforce.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">Zeo-Force</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CMB;
