import React, { Component } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";

function Gromaxx() {
  const navigate = useNavigate();

  return (
    <div className="App" id="Environment">
      <Nav />
      <section className="text-black body-font xl:px-6">
        <div className="container px-5 xl:px-10 py-2 mx-auto flex flex-wrap">
          <div className="flex flex-wrap md:-m-2 -m-1">
            <div className="md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg flex items-center justify-center h-full">
                <img
                  className="mx-auto px-10 max-h-full"
                  src="images/product_gromaxx.png"
                  alt="Gromaxx"
                />
              </div>
            </div>

            <div className="lg:flex-grow md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg p-5 h-full">
                <h1 className="text-3xl xl:text-4xl font-bold  py-5">
                  GroMAXX
                </h1>
                <h4 className="text-xl pb-3  font-semibold">
                  No.1 Rapid Growth Booster
                </h4>

                <h2 className="text-2xl font-bold  mb-2">Benefits</h2>
                <ul className="list-square pl-5">
                  <li>Promotes Rapid Feeding for Faster Growth.</li>
                  <li>Boosts Shrimp Growth for Healthy Harvests.</li>
                  <li>Enhances Feed Digestibility and Improves FCR.</li>
                  <li>Strengthens Immunity for Disease Resistance.</li>
                </ul>

                <h2 className="text-2xl font-bold  mb-2 mt-5">
                  Dosage and method of application
                </h2>
                <p className="before:content-['\2022'] before:mr-2">
                  Mix 2-3 g/kg of feed with a suitable amount of water and spray
                  evenly onto the feed. Allow to dry for 20 minutes before
                  feeding. GroMAXX™ can be used in all meals throughout the
                  culture period. <br />
                  <em>(or as advised by an Aqua Consultant)</em>
                </p>
                {/* <p className="pt-2 before:content-['\2022'] before:mr-2">
                  Directly broadcast evenly throughout the pond. <br />{" "}
                  <em>(or as advised by an Aqua Consultant)</em>
                </p> */}

                <h2 className="text-2xl font-bold  mt-4">Composition</h2>
                <p className="py-3 pr-10 before:content-['\2022'] before:mr-2">
                  <b>Each 1 gm contains</b>
                  <br /> Methionine (100mg), Lysine (50mg), Tryptophan (100mg),
                  FOS (300mg), Moringa (20mg), Licorice (20mg), Sorghum (30mg),
                  Calcium (20mg), Zinc (2mg), Selenium (2mg), Lipase (100IU),
                  Stabilizers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className=" ">
        <h2 className="text-4xl font-bold ml-2 sm:ml-10 px-5 pt-5 2xl:px-44">
          {" "}
          Other Products
        </h2>
      </div>
      <div className="flex flex-wrap xl:ml-10 2xl:px-40">
        <div className="md:flex sm:w-1/2 lg:w-1/4 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg cursor-pointer"
            onClick={() => navigate("/virkill")}
          >
            <img className="mx-auto " src="images/product_virkill.png" />
            <h3 className="text-xl font-bold  text-center">VIRKILL</h3>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/removir")}
          >
            <img className="mx-auto" src="images/product_removir.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">RemoVIR</span>
              </h3>
            </div>
          </div>
        </div>

        <div className="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            className="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/doturbo")}
          >
            <img className="mx-auto" src="images/product_doturbo.png" alt="" />

            <div>
              <h3 className="text-xl font-bold   text-center mb-2">
                <span className="lust ">D O Turbo</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gromaxx;
