import React, { useState } from "react";
import NavBar from "./components/Navbar";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import PondBp from "./components/PondBP";
import CMB from "./components/CMB";
import Crampcure from "./components/Crampcure";
import ScrollToTop from "./Scrolltotop";
import Alkasense from "./components/Alkasense.js";
import Aquapro from "./components/Aquapro.js";
import Zeoforce from "./components/Dotablet.js";
import MinergyMax from "./components/minergymax.js";
import PhotoSynthetic from "./components/photosynthetic.js";
import DoTurbo from "./components/DoTurbo.js";
import Gromaxx from "./components/Gromaxx.js";
import Virkill from "./components/Virkill.js";
import Removir from "./components/Removir.js.js";

function App() {
  const [issearch, setisSearch] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="absolute w-full">
      <div
        className={
          issearch
            ? " bg-contain h-full absolute w-full "
            : "opacity-1 bg-contain h-full absolute w-full"
        }
      >
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<NavBar />} />
            <Route path="/teaseedpowder" element={<PondBp />} />
            <Route path="/doTurbo" element={<DoTurbo />} />
            <Route path="/gromaxx" element={<Gromaxx />} />
            <Route path="/virkill" element={<Virkill />} />
            <Route path="/removir" element={<Removir />} />
            <Route path="/alkasense" element={<Crampcure />} />
            <Route path="/aquapromsl" element={<CMB />} />
            <Route path="/gutbot" element={<Alkasense />} />
            <Route path="/ecobot" element={<Aquapro />} />
            <Route path="/zeoforce" element={<Zeoforce />} />
            <Route path="/minergymax" element={<MinergyMax />} />
            <Route path="/ps" element={<PhotoSynthetic />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
